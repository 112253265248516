import { getTokenCookie } from "lib/auth-cookies-new"
import { AuthToken } from "lib/auth_token"
import { NextPage, NextPageContext } from "next"
import { Component } from "react"
import Head from "next/head"

export type AuthProps = {
  auth?: AuthToken
}

export default function withAuth<P extends AuthProps>(
  WrappedComponent: NextPage<P>,
  shouldRedirect = true,
) {
  return class extends Component<P> {
    static async getInitialProps(ctx: NextPageContext) {
      let callbackPath = ctx.req?.url
      let auth_page = null

      const url = new URL("https://test.com" + callbackPath)
      auth_page = url.searchParams.get("auth_page")
      const utmSource = url.searchParams.get("utm_source") || undefined
      const utmCampaign = url.searchParams.get("utm_campaign") || undefined
      const gclid = url.searchParams.get("gclid") || undefined
      const adName = url.searchParams.get("ad_name") || undefined
      const utmTerm = url.searchParams.get("utm_term") || undefined
      const loginContentType =
        url.searchParams.get("loginContentType") || undefined
      if (loginContentType) {
        url.searchParams.delete("loginContentType")
        callbackPath = url.pathname + url.search
      }

      const isIeltsAbTest = url.searchParams.get("abTest") || undefined //will be removing this after ab test

      const token = getTokenCookie(ctx)
      const auth = new AuthToken(token)
      const initialProps = { auth }
      if (auth && auth.isExpired) {
        //will be removing isIeltsAbTest after ab test
        if (shouldRedirect && !isIeltsAbTest) {
          let pageName = "login"
          if (auth_page && auth_page !== "") {
            pageName = auth_page
          }
          ctx.res?.writeHead(302, {
            Location: this.buildAuthUrl(
              pageName,
              callbackPath,
              utmSource,
              utmCampaign,
              utmTerm,
              gclid,
              adName,
              loginContentType,
            ),
          })
          ctx.res?.end()
        }
      }
      if (WrappedComponent.getInitialProps) {
        const componentProps = await WrappedComponent.getInitialProps(ctx)
        return {
          ...componentProps,
          ...initialProps,
        }
      }
      return initialProps
    }

    static buildAuthUrl(
      authPage: string,
      callbackPath?: string,
      utmSource?: string,
      utmCampaign?: string,
      utmTerm?: string,
      gclid?: string,
      adName?: string,
      loginContentType?: string,
    ): string {
      let finalUrl = `/${authPage}?redirected=true&callbackUrl=${encodeURIComponent(
        callbackPath || "",
      )}`
      if (utmSource) finalUrl += `&utm_source=${utmSource}`
      if (utmCampaign) finalUrl += `&utm_campaign=${utmCampaign}`
      if (utmTerm) finalUrl += `&utm_term=${utmTerm}`
      if (gclid) finalUrl += `&gclid=${gclid}`
      if (adName) finalUrl += `&ad_name=${adName}`
      if (loginContentType) finalUrl += `&loginContentType=${loginContentType}`
      return finalUrl
    }

    get auth() {
      return new AuthToken(this.props.auth?.token)
    }

    render() {
      return (
        <>
          {shouldRedirect && (
            <Head>
              <meta name="robots" content="noindex, nofollow" />
            </Head>
          )}
          <WrappedComponent {...this.props} auth={this.auth} />
        </>
      )
    }
  }
}
